import { Link } from "gatsby"
import React from "react"
import { Helpers } from "../../services/Helpers"
import { Mountain } from "../../models/NationalPark"
import Colors from "../../constants/Colors"
import { FaMountain } from "react-icons/fa"
import Routes from "../../constants/Routes"

interface Props {
  mountain: Mountain
  parkname: string
}

export default function MountainCard({ mountain, parkname }: Props) {
  const link = `/${Helpers.sanitizeUrl(parkname)}/${
    Routes.MOUNTAIN_ROUTE
  }${Helpers.sanitizeUrl(mountain.mountain_title[0].text)}/`

  return (
    <div className="max-w-sm lg:max-w-md rounded overflow-hidden shadow-lg mb-6 mr-0 md:mr-4">
      <Link to={link} title={mountain.mountain_title[0].text}>
        <div
          style={{
            background: `url(${mountain.mountain_image.url})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: 400,
          }}
        ></div>
      </Link>
      <div className="px-6 py-4 border-b border-lightest h-28">
        <Link to={link}>
          <h4 style={{ marginTop: 5, marginBottom: 0 }}>
            {mountain.mountain_title[0].text}
          </h4>
          <FaMountain
            size={14}
            className="inline-block"
            color={Colors.themeColor}
            style={{ marginBottom: 2 }}
          />
          <span style={styles} className="ml-2">
            {mountain.mountain_elevation} meters
          </span>
        </Link>
      </div>
    </div>
  )
}

const styles = {
  color: Colors.grey,
  fontSize: 12,
} as React.CSSProperties
