import React from "react"
import Layout from "../components/layout/Layout"
import WideContainer from "../components/layout/WideContainer"
import MetaData from "../components/seo/MetaData"
import { NationalPark, Mountain } from "../models/NationalPark"
import MountainCard from "../components/cards/MountainCard"
import ParkSectionText from "../components/nationalpark/ParkSectionText"

interface Props {
  pageContext: PageContext
  location: any
}

interface PageContext {
  mountains: Mountain[]
  park: NationalPark
}

const MountainsTemplate: React.FunctionComponent<Props> = ({
  pageContext,
  location,
}) => {
  return (
    <Layout>
      <MetaData
        title={`Mountains in ${pageContext.park.national_park_name[0].text}`}
        description={`Mountains in ${pageContext.park.national_park_name[0].text}. Read more about the mountains and get directions to visit.`}
        thumbnailUrl={pageContext.mountains[0].mountain_image.url}
        imageAlt={`${pageContext.mountains[0].mountain_image.alt}`}
        pageUrl={location.pathname}
      />
      <div className={`container mx-auto px-4`}>
        <h1 className="mt-8 md:mb-0 mb-2">
          Explore mountains in {pageContext.park.national_park_name[0].text}
        </h1>
        <p className="mt-2 mb-4">
          Read more about the mountain peaks in the national park. Select a
          mountain and see more details.
        </p>
      </div>
      <WideContainer center={false}>
        <div className="grid grid-cols-1 md:grid-cols-3 py-4">
          {pageContext.mountains.map((element, index) => {
            return (
              <MountainCard
                key={index.toString()}
                mountain={element}
                parkname={pageContext.park.national_park_name[0].text}
              />
            )
          })}
        </div>
      </WideContainer>
      <ParkSectionText />
    </Layout>
  )
}

export default MountainsTemplate
